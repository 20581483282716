import axios, { CancelTokenSource } from 'axios';
import { Auth } from '@aws-amplify/auth';

import { ApiUrls, appConfig } from '../config/app-config';
import { SessionStorageKeys } from '../config/storage-config';

async function getJWToken() {
  try {
    const currentSession = await Auth.currentSession();

    return currentSession.getIdToken().getJwtToken();
  } catch (error) {
    throw new Error(`An error occurred: ${error}.`);
  }
}
async function getAccessToken() {
  try {
    const currentSession = await Auth.currentSession();

    return currentSession.getAccessToken().getJwtToken();
  } catch (error) {
    throw new Error(`An error occurred: ${error}.`);
  }
}

export async function client(
  endpoint: string,
  { requestType, body, ...customConfig }: any = {},
  apiUrl: ApiUrls[keyof ApiUrls] = appConfig.apiUrls.micro,
  AccessToken = false
) {
  const jwtToken = await getJWToken();

  const headers: any = {
    'Content-Type': 'application/json; charset=UTF-8'
  };

  if (jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  }
  if (AccessToken) {
    const accessToken =
      window.localStorage.getItem('ac') || (await getAccessToken());
    headers.accessToken = accessToken;
  }
  const storeNumber = sessionStorage.getItem(SessionStorageKeys.STORE_NUMBER);
  if (storeNumber) {
    headers.storeNumber = storeNumber;
  }

  const requestConfig: any = {
    method: requestType,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers
    }
  };

  if (body) {
    requestConfig.data = JSON.stringify(body);
  }

  return axios(`${apiUrl}/${endpoint}`, requestConfig).then(
    response => response.data
  );
}

export const getCancelTokenSource = (): CancelTokenSource =>
  axios.CancelToken.source();
